import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"

import LayoutCourse from "../../../../../layouts/course"

import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import LetterNote from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-note"
import LetterSidebar from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-sidebar"
import MoodBarometer from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/mood-barometer"
import Paragraph from "../../../../../components/paragraph"
import AudioPlayer from "../../../../../components/audio-player"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/stimmungsbarometer/aufgabe-2" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <Paragraph>
          Kurz vor dem Aufbruch zu dieser Reise und auch währenddessen hat
          Hölderlin Briefe an seine Freunde und Familie verfasst. Sehen wir uns
          darin nun einmal an, in welcher Stimmung er zu dieser Zeit war...
        </Paragraph>
        <LetterNote />
        <LetterSidebar
          title="Brief an Casimir von Böhlendorf. Nürtingen, den 4. December 1801"
          before={<AudioPlayer src={`kurse/reiseerinnerung/brief-1`} />}
          main={
            <>
              <p>
                Von mir selber und wie es mir gegangen ist bisher [...] davon
                will ich mit nächstem Dir aus der Nachbarschaft Deines Spaniens,
                nämlich aus Bordeaux schreiben, wohin ich als Hauslehrer und
                Privatprediger in einem deutsch evangelischen Hauße nächste
                Woche abreise. Ich werde den Kopf ziemlich beisammen halten
                müssen, in Frankreich, in Paris; auf den Anblik des Meeres, auf
                die Sonne der Provence freue ich mich auch.
              </p>
              <br />
              <p>
                O Freund! die Welt liegt heller vor mir, als sonst, und ernster.
                [...] Denn unter allem, was ich schauen kann von Gott, ist
                dieses Zeichen mir das auserkorene geworden. Sonst konnt‘ ich
                jauchzen über eine neue Wahrheit, eine bessere Ansicht deß, das
                über uns und um uns ist, jetzt fürcht‘ ich, daß es mir nicht
                geh‘ am Ende, wie dem alten Tantalus, dem mehr von Göttern ward,
                als er verdauen konnte.
              </p>
              <br />
              <p>
                Aber ich thue, was ich kann, so gut ichs kann, und denke, wenn
                ich sehe, wie ich auf meinem Wege auch dahin muß wie die andern,
                daß es gottlos ist und rasend, einen Weg zu suchen, der vor
                allem Anfall sicher wäre...
              </p>
              <br />
              <p>
                Und nun leb wohl, mein Theurer! bis auf weiteres. Ich bin jezt
                voll Abschieds. Ich habe lange nicht geweint. Aber es hat mich
                bittre Thränen gekostet, da ich mich entschloß, mein Vaterland
                noch jezt zu verlassen, vieleicht auf immer. Denn was hab‘ ich
                lieberes auf der Welt? Aber sie können mich nicht brauchen.
                Deutsch will und muß ich übrigens bleiben, und wenn mich die
                Herzens- und die Nahrungsnoth nach Otaheiti triebe.
              </p>
            </>
          }
          sidebar={
            <MoodBarometer
              onChange={({ state }) => {
                setAnswer({
                  courseId: "reiseerinnerung",
                  chapterId: "01-suedfrankreich",
                  taskId: "stimmungsbarometer-1",
                  answer: state,
                })
              }}
            />
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
